var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"release","archiveBladeName":"release-archives","bladeName":"releases","bladesData":_vm.bladesData,"canDelete":"","getParams":function (bladeData) { return { includeDetails: false, departureLocationID: bladeData.data != null ? bladeData.data.locationID : null }},"headers":[
        { text: 'Location', value: 'departureLocation.locationName' },
        { text: 'Receiver', value: 'buyer.companyName' },
        { text: 'Courier', value: 'courier.companyName' },
        { text: 'When', value: 'dueDepartureOn', display: true },
        { text: 'Status', value: 'status', display: true }],"minimizeOnSelect":"","navigation":"releases","onCanDelete":function (item) { return item.sellerID != item.courierID && item.courier.isManagedByAnotherAccount },"title":"Releases","useServerPagination":""},scopedSlots:_vm._u([{key:"dueDepartureOn",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateAndTime")(item.dueDepartureOn != null ? item.dueDepartureOn : item.releasedOn))+" ")]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [(item.releasedOn != null)?_c('span',[_vm._v("Released")]):_c('span',[_vm._v("Pending")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }