<template>
    <BT-Blade-Items
        addBladeName="release"
        archiveBladeName="release-archives"
        bladeName="releases"
        :bladesData="bladesData"
        canDelete
        :getParams="bladeData => { return { includeDetails: false, departureLocationID: bladeData.data != null ? bladeData.data.locationID : null }}"
        :headers="[
            { text: 'Location', value: 'departureLocation.locationName' },
            { text: 'Receiver', value: 'buyer.companyName' },
            { text: 'Courier', value: 'courier.companyName' },
            { text: 'When', value: 'dueDepartureOn', display: true },
            { text: 'Status', value: 'status', display: true }]"
        minimizeOnSelect
        navigation="releases"
        :onCanDelete="item => { return item.sellerID != item.courierID && item.courier.isManagedByAnotherAccount }"
        title="Releases"
        useServerPagination>
        <template slot="dueDepartureOn" slot-scope="{ item }">
            {{ item.dueDepartureOn != null ? item.dueDepartureOn : item.releasedOn | toShortDateAndTime }}
        </template>
        <template slot="status" slot-scope="{ item }">
            <span v-if="item.releasedOn != null">Released</span>
            <span v-else>Pending</span>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Releases-Blade',
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    }
}
</script>